export const attributePaSize = [
    "6y", "8y", "10y", "12y", "14y", "330ml", "380ml", "480ml", "700ml", "720ml",
    "chld", "inf", "xss", "1:5", "js", "jm", "jl", "jxl", "sm", "s/m", "ml", "m/l",
    "sb", "mb", "lb", "xlb", "yth", "360ml", "460ml", "600ml", "735ml", "ys", "ym",
    "yl", "lxl", "yxl", "1:18", "1:24", "1:25", "1:32", "1:43", "half scale",
    "uk (7.5-11.5)", "92", "092", "104", "110", "116", "128", "140", "152", "164",
    "176", "182", "310ml", "kids", "one size", "one size fits most", "osfm", "xxs",
    "xs", "s", "m", "l", "xl", "xxl", "2xl", "3xl", "4xl", "youth", "extra small",
    "small", "small – medium", "medium", "medium – large", "large", "extra large",
    "6", "8", "10", "12", "14", "16", "18", "20"
];