import React, {useState} from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from './productSingleSlice';
import { useParams } from 'react-router-dom';
import AddToCartForm from './AddToCartForm';
import Content from '../../layouts/Content';
import ProductSliderRelated from '../productSliderRelated/ProductSliderRelated';
import ImageBox from '../imageBox/ImageBox';
import Style from "./ProductSingleDetails.module.css";
import ContentSwiper from '../contentSwiper/ContentSwiper';
import ProductSingleBreadcrumbs from './ProductSingleBreadcrumbs';
import Theme from '../../theme.module.css';
import Collapsible from '../collapsible/Collapsible';
import SizingInformation from '../sizingInformation/SizingInformation';
import DeliveryInformation from '../deliveryInformation/DeliveryInformation';
import { Helmet } from "react-helmet-async";
import { CurrencyFormat } from '../internationalisation/internationalisation';
import { useTranslation } from 'react-i18next';
import gs_dataLayer from '../../gs_modules/dataLayer';
import { attributePaSize } from '../../data/attributes';


export default function ProductSingleDetails() {

    const {t, i18n} = useTranslation('common');
    const params = useParams();
    const dispatch = useDispatch();
    const {product} = useSelector(state => state.productSingle);
    const [selectedVariation, setSelectedVariation] = useState();
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [productAvailable, setProductAvailable] = useState(true);
    const [imageSlides, setImageSlides] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [pageTitle, setPageTitle] = useState(null);
    const [pageDesc, setPageDesc] = useState(null);
    const {isMobile} = useSelector(state => state.mobile);
    const {currentCurrency, pricesInclusiveTax, localeLoading} = useSelector(state => state.internationalisation);
    const [sortedProductAttributes, setSortedProductAttributes] = useState([]);

    let productDescription = {__html: product.description};


    useEffect(() => {
        let getProductParams = {
            slug: params.slug,
            event: 'viewContent'
        }
        dispatch(getProduct(getProductParams));
    }, [])

    // product data ready
    useEffect(() => {

        if (!product.name || !currentCurrency) {
            return;
        }

        console.log(product);

        // set product available initially
        setProductAvailable(true);

        // set image slides
        if (product.images) {
            setImageSlides(product.images.map((image) => (
                console.log(image),
                <ImageBox text="" image={image} link="" />
            )));
        }

        // set breadcrumbs
        setBreadcrumbs([
            {text: t('breadcrumbs.catalog'), link: '/catalog'},
            // {text: product.category, link: '/shop/' + product.categorySlug},
            {text: product.name, link: ''}
        ])

        // sort attributes
        if (product.attributes) {
            let sortedAttributes = [];

            Object.keys(product.attributes).forEach((attributeKey) => {
                if (attributeKey === 'attribute_pa_size') {
                    // Sort using predefined order in attributePaSize
                    let attributeValues = product.attributes[attributeKey];
                    let sortedValues = [...attributeValues].sort((a, b) => {
                        a = a.toLowerCase();
                        b = b.toLowerCase();

                        return attributePaSize.indexOf(a) - attributePaSize.indexOf(b);
                    });

                    sortedAttributes[attributeKey] = sortedValues;
                } else {
                    // Keep other attributes as they are
                    sortedAttributes[attributeKey] = product.attributes[attributeKey];
                }
            });

            setSortedProductAttributes(sortedAttributes);
        }

        // init selected attribute state
        if (product.attributes) {
            const selectedAttributesObj = [];

            // get query string param attribute_pa_size
            const urlParams = new URLSearchParams(window.location.search);
            let sizeQryParam = urlParams.get('attribute_pa_size');

            Object.keys(product.attributes).forEach((attributeKey) => {

                // set selected attribute to query string param if it exists
                if (sizeQryParam) {
                    sizeQryParam = sizeQryParam.toLowerCase();

                    product.attributes[attributeKey].forEach((size) => {
                        if (size == sizeQryParam) {
                            selectedAttributesObj[attributeKey] = size;
                        }
                    });

                    // if there is no matching size set to the first one
                    if (!selectedAttributesObj[attributeKey]) {
                        selectedAttributesObj[attributeKey] = product.attributes[attributeKey][0];
                    }
                }

                // else set the first one attribute / or first one in stock if possible
                else {
                    // first one
                    selectedAttributesObj[attributeKey] = product.attributes[attributeKey][0];

                    // Update to first in stock if available if there is only one variation attribute key 
                    if (product.variations && Object.keys(product.attributes).length == 1) {
                        let firstInStockFound = false;

                        product.attributes[attributeKey].forEach((size) => {
                            if (firstInStockFound) {
                                return;
                            }

                            Object.keys(product.variations).forEach((variationID) => {
                                if (product.variations[variationID].attributes[attributeKey] == size) {
                                    if (product.variations[variationID].stock >= 1) {
                                        console.log('First in stock: ' + size + ' (' + attributeKey + ')');
                                        selectedAttributesObj[attributeKey] = product.variations[variationID].attributes[attributeKey];
                                        firstInStockFound = true;
                                        return;
                                    }
                                }
                            })
                        });
                    }
                }
            });

            setSelectedAttributes(selectedAttributesObj);
        }

        // simple product
        else {
            setSelectedAttributes([]);
            setSelectedVariation('');
        }

        // set meta
        if (product.name && product.description) {
            setPageTitle(product.name + ' | The Raceworks');
            setPageDesc(product.description);
        }

        // datalayer - item viewed
        gs_dataLayer.viewProduct(product, currentCurrency);

    }, [product, currentCurrency]);

    // handle attribute select
    function handleAttributeSelect(attributeKey, option) {
        setSelectedAttributes({...selectedAttributes, [attributeKey]: option});
    }

    // Attempt to select variation
    useEffect(() => {

        if (!selectedAttributes || product.type !== 'variable') {
            return;
        }
        
        // check all attributes are selected
        Object.keys(selectedAttributes).forEach((attributeKey) => {
            if (selectedAttributes[attributeKey] == '') {
                return;
            }
        });

        // find variation
        Object.keys(product.variations).map((variationID) => {
            let isThisVariation = true;

            Object.keys(product.variations[variationID].attributes).map((attributeKey) => {
                let attributeVal = product.variations[variationID].attributes[attributeKey];
                
                if (selectedAttributes[attributeKey] !== attributeVal) {
                    // it's not this one, go to the next product.variations
                    isThisVariation = false;
                    return;
                }
            });

            // found it
            if (isThisVariation) {
                console.log('Selected product variation: ' + variationID);
                setSelectedVariation(variationID);

                // check if this product is available
                if (product.variations[variationID].stock >= 1) {
                    setProductAvailable(true);
                } else {
                    setProductAvailable(false);
                }

                return false;
            }
        })

    }, [product, selectedAttributes]);


    return (
        <div>
            {pageTitle && pageDesc && product.slug && 
                <Helmet>
                    <title>{pageTitle.toString()}</title>
                    <meta name="description" content={pageDesc.toString()} />
                    
                    <link rel="alternate" hreflang="en-gb" href={`https://uk.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-us" href={`https://www.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-au" href={`https://au.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-nz" href={`https://nz.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="nl-nl" href={`https://nl.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-nl" href={`https://en-nl.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="fr-fr" href={`https://fr.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-fr" href={`https://en-fr.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="de-de" href={`https://de.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-de" href={`https://en-de.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="es-es" href={`https://es.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-es" href={`https://en-es.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="pt-pt" href={`https://pt.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="en-pt" href={`https://en-pt.theraceworks.com/product/${product.slug}`} />
                    <link rel="alternate" hreflang="x-default" href={`https://www.theraceworks.com/product/${product.slug}`} />
                </Helmet>
            }
            {product.slug && product.name !== '' && !localeLoading ?
                <div className={`${Style.productOuter} ${isMobile && Style.mobile}`}>

                    {!isMobile &&
                        <ProductSingleBreadcrumbs crumbs={breadcrumbs} />
                    }

                    <div className={`${Theme.flexRow} ${Style.cols}`} style={{marginTop: '30px'}}>
                        <div className={Style.productImageSwiper}>
                            <ContentSwiper swiperSlides={imageSlides} numSlides={1} classNames="singleProdImageSwiper" />
                        </div>
                        <div className={Style.productDetails}>
                            <h1>{product.name}</h1>

                            {product.type == 'variable' && product.attributes && sortedProductAttributes ? (
                                <>
                                    {Object.keys(sortedProductAttributes).map((attributeKey) => (
                                        <div className={Style.attributeSelect}>
                                            <ul>
                                                {sortedProductAttributes[attributeKey].map((option) => (
                                                    <li attributeKey={attributeKey} className={selectedAttributes[attributeKey] == option ? Style.selected : ''}>
                                                        <a onClick={() => handleAttributeSelect(attributeKey, option)}>{option}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}

                                    {selectedVariation && product.variations[selectedVariation] && product.variations[selectedVariation].sku && (
                                        <p className={Style.sku}>ID: {product.variations[selectedVariation].sku}</p>
                                    )}

                                    {selectedVariation && product.variations[selectedVariation] && product.variations[selectedVariation].price && currentCurrency ? (
                                        <>
                                            {pricesInclusiveTax && product.variations[selectedVariation].tax_amount > 0 ? (
                                                <p>
                                                    {product.variations[selectedVariation].regular_price && product.variations[selectedVariation].price !== product.variations[selectedVariation].regular_price &&
                                                        <span className={Style.oldPrice}>{CurrencyFormat(parseFloat(product.variations[selectedVariation].regular_price.toFixed(2)) * ((parseFloat(product.tax_rate) / 100) + 1), currentCurrency)}</span>
                                                    }
                                                    {CurrencyFormat(parseFloat(product.variations[selectedVariation].price.toFixed(2)) + parseFloat(product.variations[selectedVariation].tax_amount), currentCurrency)}
                                                </p>
                                            ) : (
                                                <p>
                                                    {product.variations[selectedVariation].regular_price && product.variations[selectedVariation].price !== product.variations[selectedVariation].regular_price &&
                                                        <span className={Style.oldPrice}>{CurrencyFormat(product.variations[selectedVariation].regular_price, currentCurrency)}</span>
                                                    }
                                                    {CurrencyFormat(product.variations[selectedVariation].price, currentCurrency)} {product.variations[selectedVariation].tax_amount > 0 && <span className={Style.priceSuffix}>{t('product.ex-tax')}</span>}
                                                </p>
                                            )}
                                        </>
                                    ) : (
                                        product.price  ? (
                                            <>
                                                {pricesInclusiveTax && product.tax_amount > 0 ? (
                                                    <p>
                                                        {product.regular_price && product.price !== product.regular_price &&
                                                            <span className={Style.oldPrice}>{CurrencyFormat(parseFloat(product.regular_price.toFixed(2)) * ((parseFloat(product.tax_rate) / 100) + 1), currentCurrency)}</span>
                                                        }
                                                        {CurrencyFormat(parseFloat(product.price.toFixed(2)) + parseFloat(product.tax_amount), currentCurrency)}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {product.regular_price && product.price !== product.regular_price &&
                                                            <span className={Style.oldPrice}>{CurrencyFormat(product.regular_price, currentCurrency)}</span>
                                                        }
                                                        {CurrencyFormat(product.price, currentCurrency)} {product.tax_amount > 0 && <span className={Style.priceSuffix}>{t('product.ex-tax')}</span>}
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p>Price not available</p>
                                        )
                                    )}
                                </>
                            ) : ( // simples
                                <>
                                    {product.sku && (
                                        <p className={Style.sku}>ID: {product.sku}</p>
                                    )}

                                    {product.price && currentCurrency &&
                                        <>
                                            {pricesInclusiveTax && product.tax_amount > 0 ? (
                                                <p>
                                                    {product.regular_price && product.price !== product.regular_price &&
                                                        <span className={Style.oldPrice}>{CurrencyFormat(parseFloat(product.regular_price) + parseFloat(product.regular_tax_amount), currentCurrency)}</span>
                                                    }
                                                    {CurrencyFormat(parseFloat(product.price) + parseFloat(product.tax_amount), currentCurrency)}
                                                </p>
                                            ) : (
                                                <p>
                                                    {product.regular_price && product.price !== product.regular_price &&
                                                        <span className={Style.oldPrice}>{CurrencyFormat(product.regular_price, currentCurrency)}</span>
                                                    }
                                                    {CurrencyFormat(product.price, currentCurrency)} {product.tax_amount > 0 && <span className={Style.priceSuffix}>{t('product.ex-tax')}</span>}
                                                </p>
                                            )}
                                        </>
                                    }
                                </>
                            )}
                            
                            <AddToCartForm selectedVariation={selectedVariation} product={product} productAvailable={productAvailable} />
                            
                            <Collapsible title={t('product.description')} open={true} classNames={Style.singleProductInformation}>
                                {productDescription && <p dangerouslySetInnerHTML={productDescription}></p>}
                            </Collapsible>

                            <Collapsible title={t('product.sizing')} classNames={Style.singleProductInformation}>
                                <SizingInformation />
                            </Collapsible>
                            
                            <Collapsible title={t('product.delivery-returns')} classNames={Style.singleProductInformation}>
                                <DeliveryInformation />
                            </Collapsible>

                            {product.related_products && product.related_products.length >= 4 && isMobile && 
                                <>
                                    <h4>{t('product.you-might-also-like')}</h4>
                                    <ProductSliderRelated sliderProducts={product.related_products} classNames="single-product-related-products" numSlides={2} />
                                </>
                            }
                        </div>
                    </div>

                    {product.related_products && product.related_products.length >= 4 && !isMobile &&
                        <>
                            <h4>{t('product.you-might-also-like')}</h4>
                            <ProductSliderRelated sliderProducts={product.related_products} classNames="single-product-related-products" numSlides={4} />
                        </>
                    }
                </div>
            : 
            <div>
                {/* <p>Sorry we couldn't find this product</p> */}
            </div>
            }
        </div>
    )
}