import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import Style from "./MobileMenu.module.css";
import { Link } from "react-router-dom";
import LanguageSelect from "../internationalisation/LanguageSelect";
import { teamsDrivers } from '../../data/teams-drivers';
import MobileMenuTeam from "./MobileMenuTeam";


export default function MobileMenu({mobileMenuActive, setMobileMenuActive}) {

    const [subMenuActive, setSubMenuActive] = useState('parent');
    const [activeF1Team, setActiveF1Team] = useState('');
    const {t, i18n} = useTranslation('common');

    function handleClickOffMenu(e) {
        if (e.target == e.currentTarget) {
            console.log('clicked off menu');
            setMobileMenuActive(false);
            setSubMenuActive('parent');
        }
    }

    // Close mobile menu when clicking a link
    useEffect(() => {

        function handleGlobalClick(event) {
            // Check if the clicked element is a Link
            if (event.target.tagName === "A" && event.target.closest("li")) {
                setMobileMenuActive(false);
                setSubMenuActive('parent');
            }
        }

        document.addEventListener("click", handleGlobalClick);

        return () => {
            document.removeEventListener("click", handleGlobalClick);
        };
    }, []);

    return (
        <>
            <CSSTransition
                in={mobileMenuActive}
                timeout={0}
                classNames={{
                    enter: "mobileMenuEnter",
                    enterActive: "mobileMenuEnterActive",
                    enterDone: "mobileMenuEnterDone",
                    exitActive: "mobileMenuExitActive",
                    exitDone: "mobileMenuExitDone",
                }}
                unmountOnExit
            >
                <div className={Style.menuBack} onClick={(e) => handleClickOffMenu(e)}>
                    <img src={require('../../assets/img/cross.svg').default} alt="close" onClick={(e) => handleClickOffMenu(e)} />

                    <div className={`${Style.mobileMenu} mobileMenu`}>
                        <div className={`${Style.mobileMenuInner} ${subMenuActive !== '' && Style.subMenuActive}`}>
                        
                            <CSSTransition
                                in={subMenuActive == 'parent'}
                                timeout={500}
                                classNames="fade"
                                unmountOnExit
                            >
                                <div className={Style.parentMenu}>
                                    <ul className={Style.mainParentList}>
                                        <li onClick={() => {setSubMenuActive('f1')}}>{t('mobile-menu.f1')}</li>
                                        <li onClick={() => {setSubMenuActive('motorsports')}}>{t('mobile-menu.motorsports')}</li>
                                        <li onClick={() => {setSubMenuActive('sports')}}>{t('mobile-menu.sports')}</li>
                                        <li onClick={() => {setSubMenuActive('items')}}>{t('mobile-menu.department')}</li>
                                        <li><Link to="/catalog/sale">{t('header.sale')}</Link></li>
                                    </ul>

                                    <ul className={Style.accountParentList}>
                                        <li>{t('menu.my-account')}</li>
                                        <li>{t('menu.my-cart')}</li>
                                    </ul>
                                    
                                    <ul className={Style.accountParentList}>
                                        <li><Link to="/delivery-information">{t('footer.delivery')}</Link></li>
                                        <li><Link to="/returns">{t('footer.returns')}</Link></li>
                                        <li><Link to="/contact">{t('footer.contact-us')}</Link></li>
                                        <li><Link to="/privacy-policy">{t('footer.privacy-policy')}</Link></li>
                                        <li><Link to="/terms-of-use">{t('footer.terms')}</Link></li>
                                    </ul>

                                    <div className={Style.mobileLocaleSelect}>
                                        <p>{t('menu.country-select')}</p>
                                        {t('menu.youre-in')}: <LanguageSelect />
                                    </div>
                                </div>
                            </CSSTransition>


                            <CSSTransition
                                in={subMenuActive == 'f1'}
                                timeout={500}
                                classNames="fade"
                                unmountOnExit
                            >
                                <div className={`${Style.submenu} fade-enter f1-submenu`}>
                                    <div className={Style.subMenuTitleCont}>
                                        <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                        <h3>{t('mobile-menu.team')}</h3>
                                    </div>

                                    <ul>
                                        {teamsDrivers.map((team) => (
                                            <>
                                            {team.drivers && (
                                                <li>
                                                    <MobileMenuTeam team={team} activeF1Team={activeF1Team} setActiveF1Team={setActiveF1Team}/>
                                                </li>
                                            )}
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </CSSTransition>

                            <CSSTransition
                                in={subMenuActive == 'items'}
                                timeout={500}
                                classNames="fade"
                                unmountOnExit
                            >
                                <div className={`${Style.submenu} fade-enter`}>
                                    <div className={Style.subMenuTitleCont}>
                                        <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                        <h3>{t('mobile-menu.department')}</h3>
                                    </div>

                                    <ul>
                                        <li><Link to="/catalog/accessories">{t('items.accessories')}</Link></li>
                                        <li><Link to="/catalog/caps_headwear">{t('items.caps')}</Link></li>
                                        <li><Link to="/catalog/t_shirts_polos">{t('items.tshirts')}</Link></li>
                                        <li><Link to="/catalog/jackets_sweaters">{t('items.jackets')}</Link></li>
                                        <li><Link to="/catalog/legwear">{t('items.legwear')}</Link></li>
                                        <li><Link to="/catalog/socks">{t('items.socks')}</Link></li>
                                        <li><Link to="/catalog/models">{t('items.models')}</Link></li>
                                    </ul>
                                </div>
                            </CSSTransition>

                            <CSSTransition
                                in={subMenuActive == 'motorsports'}
                                timeout={500}
                                classNames="fade"
                                unmountOnExit
                            >
                                <div className={`${Style.submenu} fade-enter`}>
                                    <div className={Style.subMenuTitleCont}>
                                        <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                        <h3>{t('menu.shop-motorsport')}</h3>
                                    </div>

                                    <ul>
                                        <li><Link to="/catalog/porsche">Porsche</Link></li>
                                        <li><Link to="/catalog/bmw">BMW</Link></li>
                                        <li><Link to="/catalog/senna">Ayrton Senna</Link></li>
                                        <li><Link to="/catalog/mazerati">Mazerati FE</Link></li>
                                        <li><Link to="/catalog/pirelli">Pirelli</Link></li>
                                        <li><Link to="/catalog/ducati">Ducati</Link></li>
                                        <li><Link to="/catalog/vespa">Vespa</Link></li>
                                        <li><Link to="/catalog/vr46">VR 46</Link></li>
                                        <li><Link to="/catalog/aprilia">Aprilia</Link></li>
                                        <li><Link to="/catalog/models">Models</Link></li>
                                        <li><Link to="/catalog/formula_e">Formula E</Link></li>
                                        <li><Link to="/catalog/moto_guzzi">Moto Guzzi</Link></li>
                                        <li><Link to="/catalog/socks">Heel Tread Socks</Link></li>
                                    </ul>
                                </div>
                            </CSSTransition>

                            <CSSTransition
                                in={subMenuActive == 'sports'}
                                timeout={500}
                                classNames="fade"
                                unmountOnExit
                            >
                                <div className={`${Style.submenu} fade-enter`}>
                                    <div className={Style.subMenuTitleCont}>
                                        <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                        <h3>{t('mobile-menu.sports')}</h3>
                                    </div>

                                    <ul>
                                        <li><Link to="/catalog/chelsea">Chelsea</Link></li>
                                        <li><Link to="/catalog/manchester_united">Manchester United</Link></li>
                                        <li><Link to="/catalog/tottenham_hotspur">Tottenham Hotspur</Link></li>
                                        <li><Link to="/catalog/scottish_football_association">Scottish FA</Link></li>
                                        <li><Link to="/catalog/welsh_football_association">Welsh FA</Link></li>
                                        <li><Link to="/catalog/football_association_of_ireland">Ireland FA</Link></li>
                                        <li><Link to="/catalog/ac_milan">AC Milan</Link></li>
                                        <li><Link to="/catalog/atletico_madrid">Atletico Madrid</Link></li>
                                        <li><Link to="/catalog/celtic">Celtic</Link></li>
                                        <li><Link to="/catalog/rugby-football-union">RFU (England Rugby)</Link></li>
                                        <li><Link to="/catalog/french-federation-of-rugby">FFR (French Rugby)</Link></li>
                                        <li><Link to="/catalog/stade-toulousain">Stade Toulousain</Link></li>
                                    </ul>
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    );
}