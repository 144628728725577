import React, { useEffect, useState } from "react";
import "./MobileMenuTeam.css";
import { teamsDrivers } from '../../data/teams-drivers';
import { Link } from "react-router-dom";
import CollapsibleGroup from "../collapsibleGroup/CollapsibleGroup";
import { useTranslation } from "react-i18next";


export default function MobileMenuTeam({team, activeF1Team, setActiveF1Team}) {

    const {t, i18n} = useTranslation('common');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (activeF1Team === team.key) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [activeF1Team])

    return (
        <>
        {team.key && (
            <div className={`mobile-menu-team ${team.key} ${isOpen ? 'active' : ''}`}>
                <CollapsibleGroup title={team.name} open={isOpen} setter={setActiveF1Team} groupKey={team.key} classNames={`mobile-menu-team-collapsible`}>
                    <div style={{backgroundImage: `url(${require('../../assets/img/team-logo/' + team.key + '.svg')}`}} className="mobile-menu-team-logo" />
                    <ul>
                        <li><Link to={`/catalog/${team.key}`}>{t('header.all')} {team.name}</Link></li>
                    </ul>
                </CollapsibleGroup>
            </div>
        )}
        </>
    );
}