import React, { useState } from "react";
import { Link } from "react-router-dom";
import Theme from "../../theme.module.css";
import Style from "./DesktopMegaMenu.module.css";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import "./DesktopMegaMenuTeams.css";


export default function DesktopMegaMenu({megaMenuActive, setMegaMenuActive}) {

    // For testing
    //megaMenuActive = 'f1';

    const {t, i18n} = useTranslation('common');
    const [teamMenuActive, setTeamMenuActive] = useState('');

    return (
        <>
            <div className={Style.megaMenuCont}>
                <div className={`${Style.megaMenu} ${Theme.flexRow} ${megaMenuActive ? Style.active : null} ${megaMenuActive == 'f1' ? Style.f1 : null}`} onMouseLeave={() => setMegaMenuActive('')} >
                    <div className={Style.leftCont}>
                        
                        <CSSTransition
                            in={megaMenuActive == 'f1'}
                            timeout={500}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div id="mega-menu-teams" class="fade-enter">
                                <h3>{t('menu.team')}</h3>
                                <div className={`${Style.listCont} menu-teams-list ${teamMenuActive}`}>
                                    <div className={Style.leftColHalf}>
                                        <ul>
                                            <li onMouseOver={() => setTeamMenuActive('formula_one')} className="formula_one"><Link to="/catalog/formula_one">Formula 1</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('ferrari')} className="ferrari"><Link to="/catalog/ferrari">Ferrari</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('mercedes')} className="mercedes"><Link to="/catalog/mercedes">Mercedes</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('aston_martin')} className="aston_martin"><Link to="/catalog/aston_martin">Aston Martin</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('mclaren')} className="mclaren"><Link to="/catalog/mclaren">McLaren</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('red_bull')} className="red_bull"><Link to="/catalog/red_bull">Red Bull Racing</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('alpine')} className="alpine"><Link to="/catalog/alpine">Alpine</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('haas')} className="haas"><Link to="/catalog/haas">Haas</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('williams')} className="williams"><Link to="/catalog/williams">Williams</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('visa_cashapp_rb')} className="visa_cashapp_rb"><Link to="/catalog/visa_cashapp_rb">Visa Cash App RB</Link></li>
                                            <li onMouseOver={() => setTeamMenuActive('kick_sauber')} className="kick_sauber"><Link to="/catalog/kick_sauber">Kick Sauber</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Style.midCol}>
                                        <CSSTransition
                                            in={teamMenuActive == 'formula_one'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/formula_one">{t('header.all')} Formula 1</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'ferrari'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/ferrari">{t('header.all')} Ferrari</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'mercedes'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/mercedes">{t('header.all')} Mercedes</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'aston_martin'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/aston_martin">{t('header.all')} Aston Martin</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'mclaren'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/mclaren">{t('header.all')} McLaren</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'red_bull'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/red_bull">{t('header.all')} Red Bull Racing</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'alpine'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/alpine">{t('header.all')} Alpine</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'haas'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/haas">{t('header.all')} Haas</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'williams'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/williams">{t('header.all')} Williams</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'visa_cashapp_rb'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/visa_cashapp_rb">{t('header.all')} Visa Cash App RB</Link></li>
                                            </ul>
                                        </CSSTransition>
                                        <CSSTransition
                                            in={teamMenuActive == 'kick_sauber'}
                                            timeout={500}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <ul>
                                                <li><Link to="/catalog/kick_sauber">{t('header.all')} Kick Sauber</Link></li>
                                            </ul>
                                        </CSSTransition>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>

                        <CSSTransition
                            in={megaMenuActive == 'motorsport'}
                            timeout={500}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div id="mega-menu-motorsport" class="fade-enter">
                                <h3>{t('menu.shop-motorsport')}</h3>
                                <div className={Style.listCont}>
                                    <div className={Style.leftCol}>
                                        <ul>
                                            <li><Link to="/catalog/porsche">Porsche</Link></li>
                                            <li><Link to="/catalog/bmw">BMW</Link></li>
                                            <li><Link to="/catalog/senna">Ayrton Senna</Link></li>
                                            <li><Link to="/catalog/mazerati">Mazerati FE</Link></li>
                                            <li><Link to="/catalog/pirelli">Pirelli</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Style.midCol}>
                                        <ul>
                                            <li><Link to="/catalog/ducati">Ducati</Link></li>
                                            <li><Link to="/catalog/vespa">Vespa</Link></li>
                                            <li><Link to="/catalog/vr46">VR 46</Link></li>
                                            <li><Link to="/catalog/aprilia">Aprilia</Link></li>
                                            <li><Link to="/catalog/models">Models</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Style.rightCol}>
                                        <ul>
                                            <li><Link to="/catalog/formula_e">Formula E</Link></li>
                                            <li><Link to="/catalog/moto_guzzi">Moto Guzzi</Link></li>
                                            <li><Link to="/catalog/socks">Heel Tread Socks</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>

                        <CSSTransition
                            in={megaMenuActive == 'sport'}
                            timeout={500}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div id="mega-menu-sport" class="fade-enter">
                                <div className={Style.listCont}>
                                    <div className={Style.leftCol}>
                                        <h3>{t('menu.football')}</h3>
                                        <ul>
                                            <li><Link to="/catalog/chelsea">Chelsea</Link></li>
                                            <li><Link to="/catalog/manchester_united">Manchester United</Link></li>
                                            <li><Link to="/catalog/tottenham_hotspur">Tottenham Hotspur</Link></li>
                                            <li><Link to="/catalog/scottish_football_association">Scottish FA</Link></li>
                                            <li><Link to="/catalog/welsh_football_association">Welsh FA</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Style.midCol}>
                                        <ul>
                                            <li><Link to="/catalog/football_association_of_ireland">Ireland FA</Link></li>
                                            <li><Link to="/catalog/ac_milan">AC Milan</Link></li>
                                            <li><Link to="/catalog/atletico_madrid">Atletico Madrid</Link></li>
                                            <li><Link to="/catalog/celtic">Celtic</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Style.rightCol}>
                                        <h3>{t('menu.rugby')}</h3>
                                        <ul>
                                            <li><Link to="/catalog/rugby-football-union">RFU (England Rugby)</Link></li>
                                            <li><Link to="/catalog/french-federation-of-rugby">FFR (French Rugby)</Link></li>
                                            <li><Link to="/catalog/stade-toulousain">Stade Toulousain</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>

                        <CSSTransition
                            in={megaMenuActive == 'department'}
                            timeout={500}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div id="mega-menu-department" class="fade-enter">
                                <h3>{t('menu.department')}</h3>
                                <div className={Style.listCont}>
                                    
                                    <div className={Style.leftCol}>
                                        <ul>
                                            <li><Link to="/catalog/accessories">{t('items.accessories')}</Link></li>
                                            <li><Link to="/catalog/caps_headwear">{t('items.caps')}</Link></li>
                                            <li><Link to="/catalog/t_shirts_polos">{t('items.tshirts')}</Link></li>
                                            <li><Link to="/catalog/jackets_sweaters">{t('items.jackets')}</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Style.midCol}>
                                        <ul>
                                            <li><Link to="/catalog/legwear">{t('items.legwear')}</Link></li>
                                            <li><Link to="/catalog/socks">{t('items.socks')}</Link></li>
                                            <li><Link to="/catalog/models">{t('items.models')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>

                    </div>

                    <div className={Style.rightCont}>
                        {megaMenuActive == 'f1' ? (
                            <>
                                <CSSTransition
                                    in={teamMenuActive == 'formula_one'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_formula_one.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>
                                <CSSTransition
                                    in={teamMenuActive == 'ferrari'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_ferrari.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'mercedes'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_mercedes.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'aston_martin'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_aston_martin.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'visa_cashapp_rb'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_visa_cashapp_rb.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'alpine'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_alpine.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'haas'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_haas.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'red_bull'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_redbull.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'williams'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_williams.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'mclaren'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_mclaren.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>

                                <CSSTransition
                                    in={teamMenuActive == 'kick_sauber'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div style={{backgroundImage: `url(${require('../../assets/img/team-menu/team_menu_kick_sauber.svg').default}`}} className={Style.menuImage}/>
                                </CSSTransition>
                            </>
                        ) : megaMenuActive == 'motorsport' ? (
                            <div style={{backgroundImage: `url(${require('../../assets/img/product-models/701202349001MP1REDBULLA020hires.jpg')}`}} className={Style.menuImage}/>
                        ) : megaMenuActive == 'sport' ? (
                            <div style={{backgroundImage: `url(${require('../../assets/img/product-models/porsche_jacket.jpg')}`}} className={Style.menuImage}/>
                        ) : megaMenuActive == 'department' ? (
                            <div style={{backgroundImage: `url(${require('../../assets/img/product-models/701222311001_MP_7_MERCEDES_GREY.jpg')}`}} className={Style.menuImage}/>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}